<template>
    <div class="page page-home">
        <banner />
        <div class="home-content">
            <div class="content-1">
                <div class="title">PTE Game: A open economy for players</div>
                <div class="tips">
                    We believe successful virtual worlds are contributed by real communities. We to connect players through<br /> blockchain and create financial ecosystem for the next gaming generation.
                </div>
                <div class="video">
                    <video
                            src="https://idlemystic.s3.ap-east-1.amazonaws.com/movie/PTEG_720.mp4"
                            controls="controls"
                            muted="muted"
                            poster="../../assets/images/video-2.png"
                    />
                </div>
            </div>
            <div class="content-2">
                <div class="item">
                    <div class="title">Product Matters</div>
                    <div class="icon"><img src="../../assets/images/1.png" /></div>
                    <div class="tips">We are aiming to produce products that are not only fulfil our expectations but exceed users expectations</div>
                </div>
                <div class="item">
                    <div class="title">Community Driven</div>
                    <div class="icon"><img src="../../assets/images/2.png" /></div>
                    <div class="tips">We are creating economies driven by assets ownership of the players. It is the future of gamefi</div>
                </div>
                <div class="item">
                    <div class="title">Users First</div>
                    <div class="icon"><img src="../../assets/images/3.png" /></div>
                    <div class="tips">We are building strong connection with our users, we value our users feedback and experience in our development</div>
                </div>
            </div>
            <div class="content-3">
                <div class="title">Games</div>
                <div class="item">
                    <div class="image">
                        <video
                            src="https://idlemystic.s3.ap-east-1.amazonaws.com/movie/part2.mp4"
                            controls="controls"
                            muted="muted"
                            poster="../../assets/images/video.png"
                        />
                    </div>
                    <div class="tips">
                        Idle Mystic is the world's first 3D NFT blockchain game that builds on Polygon. Collect heroes and flight to build your legendary kingdom on the Land of Mystic.
                    </div>
                    <div class="buttons">
                        <a target="_blank" href="https://idlemystic.io">Open Dapp</a>
                    </div>
                </div>
                <div class="item">
                    <div class="image">
                        <video
                            src="https://idlemystic.s3.ap-east-1.amazonaws.com/movie/part1.mp4"
                            controls="controls"
                            muted="muted"
                            poster="../../assets/images/video-1.jpg"
                        />
                    </div>
                    <div class="tips">
                        Ready World One is a virtual world where players can develop a city together, earn reputation by helping and contributing the community.
                    </div>
                    <div class="buttons">
                        <a href="javascript:void 0" class="disabled">COMING SOON...</a>
                    </div>
                </div>
            </div>
<!--            <div class="home-form">-->
<!--                <div class="title">Get In Touch</div>-->
<!--                <div class="tips">-->
<!--                    Want to keep updated on our latest blockchain games? <br />-->
<!--                    Stay connected!-->
<!--                </div>-->
<!--                <div class="form">-->
<!--                    <div class="item">-->
<!--                        <label>Name</label>-->
<!--                        <input />-->
<!--                    </div>-->
<!--                    <div class="item">-->
<!--                        <label>Your email address</label>-->
<!--                        <input />-->
<!--                    </div>-->
<!--                    <div class="button">-->
<!--                        <button>Subscribe</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import banner from "../../components/banner";
export default {
    name: 'PageHome',
    components: {
        banner
    }
}
</script>

<style lang="less">
@import "../../less/main.less";
.page-home {
    .home-content {
        .content-1 {
            width: 960px;
            margin: 0 auto;
            @media @mobile {
                width: 100%;
            }
            .title {
                padding: 24px 0;
                font-size: 22px;
                text-align: center;
                font-family: GothamBold;
            }
            .tips {
                text-align: center;
            }
            .video {
                width: 100%;
                padding-top: 32px;
                video {
                    width: 100%;
                }
            }
        }
        .content-2 {
            display: flex;
            justify-content: space-between;
            padding: 36px 0;
            width: 960px;
            margin: 0 auto;
            @media @mobile {
                width: 100%;
                overflow: auto;
                flex-direction: column;
                box-sizing: border-box;
                padding: 12px 24px;
            }
            .item {
                width: 30%;
                display: flex;
                flex-direction: column;
                background-color: #0F0F0F;
                border-radius: 9px;
                @media @mobile {
                    width: 100%;
                    margin: 6px 0;
                }
                .title {
                    font-size: 18px;
                    text-align: center;
                    padding: 24px 0;
                    font-family: GothamBold;
                }
                .icon {
                    width: 50%;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    img {
                        width: auto;
                        height: 104px;
                    }
                }
                .tips {
                    padding: 24px;
                    text-align: center;
                }
            }
        }
        .content-3 {
            width: 100%;
            background-color: #0F0F0F;
            margin-bottom: 36px;
            .title {
                font-size: 24px;
                text-align: center;
                padding: 24px;
                font-family: GothamBold;
                @media @mobile {
                    padding: 12px;
                }
            }
            .item {
                width: 960px;
                margin: 0 auto;
                padding-bottom: 36px;
                @media @mobile {
                    width: 100%;
                }
                .image {
                    width: 100%;
                    img {
                        width: 100%;
                    }
                    video {
                        width: 100%;
                    }
                }
                .tips {
                    width: 100%;
                    text-align: center;
                    padding: 12px 36px;
                }
                .buttons {
                    display: flex;
                    justify-content: center;
                    a {
                        background-color: #008900;
                        padding: 3px 24px 3px 24px;
                        border-radius: 5px;
                        line-height: 26px;
                        font-size: 16px;
                        font-family: GothamBold;
                        &.disabled {
                            background-color: #3F3F3F;
                            cursor: no-drop;
                        }
                    }
                }
            }
        }
        .home-form {
            width: 100%;
            margin: 0 auto;
            padding: 48px 0;
            .title {
                text-align: center;
                font-size: 24px;
            }
            .tips {
                text-align: center;
                padding: 24px 0;
            }
            .form {
                width: 300px;
                margin: 0 auto;
                .item {
                    border-bottom: solid 1px #3F3F3F;
                    display: flex;
                    margin-bottom: 18px;
                    label {
                        color: #353535;
                        padding-right: 12px;
                    }
                    input {
                        background-color: transparent;
                        flex: 1;
                        color: #fff;
                        &:focus {
                            border: none!important;
                            outline: none!important;
                        }
                    }
                }
                .button {
                    display: flex;
                    padding: 24px 0;
                    justify-content: center;
                    button {
                        background-color: #008900;
                        padding: 3px 24px;
                        font-size: 16px;
                        color: #fff;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}
</style>
