<template>
    <div class="component-banner">
        <div class="banner-item">
                <span>We build blockchain games that combining<br/>
                DeFi and NFTs to create a truly unique<br/>
                and everlasting ECOSYSTEM.</span>
        </div>
        <div class="banner-content">
            <div class="content" :class="cpd_speed">
                <div class="item"><img src="../assets/images/banner-1.png" /> </div>
                <div class="item"><img src="../assets/images/banner-2.jpg" /> </div>
                <div class="item"><img src="../assets/images/banner-3.jpg" /> </div>
            </div>
        </div>
        <div class="speed-content">
            <span :class="{active: speed == 0}"></span>
            <span :class="{active: speed == 1}"></span>
            <span :class="{active: speed == 2}"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    data() {
        return {
            speed: 0,
            timeOut: null
        }
    },
    computed: {
        cpd_speed() {
            return 'speed' + this.speed
        }
    },
    mounted() {
        this.auto()
    },
    methods: {
        auto() {
            if (this.timeOut) {
                clearTimeout(this.timeOut)
            }
            let speed = this.speed + 1
            if (speed === 3) {
                speed = 0
            }
            this.timeOut = setTimeout(() => {
                this.speed = speed
                this.auto()
            }, 5000)
        }
    }
}
</script>

<style lang="less">
@import "../less/main.less";
.component-banner {
    position: relative;
    min-height: 300px;
    @media @mobile {
        min-height: 0;
    }
    .banner-item {
        position: absolute;
        z-index: 11;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            text-align: center;
            font-size: 24px;
            padding: 12px;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 9px;
            font-family: GothamBold;
            line-height: 32px;
            @media @mobile {
                font-size: 12px;
                line-height: 18px;
                padding: 6px;
            }
        }
    }
    .banner-content {
        width: 100%;
        max-width: 1500px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        .content {
            width: 300%;
            display: flex;
            position: relative;
            transform: translateX(0);
            transition: transform .5s;
            &.speed0 {
                transform: translateX(0);
            }
            &.speed1 {
                transform: translateX(-100vw);
            }
            &.speed2 {
                transform: translateX(-200vw);
            }
            .item {
                flex: 1;
                img {
                    width: 100vw;
                    height: auto;
                }
            }
        }
    }
    .speed-content {
        position: absolute;
        width: 72px;
        bottom: 24px;
        left: 50%;
        margin-left: -36px;
        display: flex;
        justify-content: center;
        @media @mobile {
            bottom: 12px;
        }
        span {
            width: 10px;
            height: 10px;
            background-color: #fff;
            border: solid 1px #fff;
            overflow: hidden;
            margin: 0 6px;
            border-radius: 50%;
            opacity: 0.9;
            &.active {
                background-color: #008900;
            }
        }
    }
}
</style>
